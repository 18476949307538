<template>
  <div class="ServiceSupport">
    <headerNav pathname="服务支持" />
    <text-common title="服务支持" :img="img" />
    <div class="waybill_query_bg">
      <theme title="运单查询" />
      <div class="zx_wx_bg">
        <img
          class="qr_code"
          width="292px"
          height="89px"
          src="../assets/service_support/qr_code.png"
          alt=""
        />
        <div class="zx_wx_border">
          <div class="border-top"></div>
          <div class="border-left"></div>
          <div class="border-bottom"></div>
          <div class="border-right"></div>
          <div class="button-wrapper"></div>
        </div>
        <img width="200px" src="../assets/service_support/zx_wx.png" alt="" />
        <div class="waybill_query_text">请微信扫码关注查询</div>
      </div>
    </div>
    <bottom-navigation />
  </div>
</template>

<script>
export default {
  data () {
    return {
      img: require("../assets/service_support/service_support_bg.png")
    }
  }

}
</script>
<style lang="less" scoped>
.ServiceSupport {
  .waybill_query_bg {
    width: 100%;
    text-align: center;
    padding: 90px 0 150px 0;
    box-sizing: border-box;

    .zx_wx_bg {
      width: 260px;
      height: 260px;
      background: #182d5d;
      position: relative;
      padding: 30px;
      box-sizing: border-box;
      margin: 110px auto 0 auto;
      color: #000;

      .zx_wx_border {
        width: 235px;
        height: 236px;
        // border: 1px dashed rgba(171, 149, 122, 0.51);
        position: absolute;
        top: 12px;
        left: 13px;
        // border-radius: 5px;
        .border-top {
          width: calc(100% - 10px);
          left: 5px;
          height: 1px;
          background-image: linear-gradient(
            to right,
            #8490a9 50%,
            #8490a9 50%,
            transparent 50%
          );
          background-size: 11px 1px;
          background-repeat: repeat-x;
          position: absolute;
        }

        .border-left {
          width: 1px;
          left: 0;
          top: -5px;
          //   height: calc(100% - 10px);
          height: 240px;
          background-image: linear-gradient(
            to top,
            #8490a9 50%,
            #8490a9 50%,
            transparent 50%
          );
          background-size: 1px 11px;
          background-repeat: repeat-y;
          position: absolute;
        }

        .border-bottom {
          width: calc(100% - 10px);
          left: 5px;
          height: 1px;
          bottom: 0;
          background-image: linear-gradient(
            to right,
            #8490a9 50%,
            #8490a9 50%,
            transparent 50%
          );
          background-size: 11px 1px;
          background-repeat: repeat-x;
          position: absolute;
        }

        .border-right {
          width: 1px;
          right: 0;
          top: -5px;
          //   height: calc(100% - 10px);
          height: 240px;
          background-image: linear-gradient(
            to top,
            #8490a9 50%,
            #8490a9 50%,
            transparent 50%
          );
          background-size: 1px 11px;
          background-repeat: repeat-y;
          position: absolute;
        }

        .button-wrapper {
          position: relative;
          width: calc(100% - 2px);
          height: calc(100% - 2px);
          //   border-radius: 4px;
          //   background: #bbb;
          top: 1px;
          left: 1px;
        }
      }

      .qr_code {
        position: absolute;
        top: -62px;
        left: -16px;
        z-index: 99;
      }
      .waybill_query_text {
        margin-top: 55px;
        opacity: 0.85;
      }
    }
  }
}
</style>